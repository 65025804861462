<template>
  <div class="container">
    <TheHeader />
    <div class="section">
      <div class="columns is-multiline is-centered">
        <div class="space"></div>
        <div class="column is-12">
          <title-bar title="Actualiza tu contraseña" class="title-bar"/>
        </div>
        <div class="column is-4 content-form">
          <div class="columns is-multiline">
            <div class="column is-12">
            <div class="box">
              <form @submit.prevent="changePassword">
                <div class="field">
                  <div class="control has-icons-right">
                    <input
                      class="input"
                      :type="!showOldPassword ? 'password' : 'text'"
                      name="passwors"
                      placeholder="Contraseña actual"
                      autofocus=""
                      autocomplete="off"
                      v-model="oldPassword"
                    />
                    <a
                      class="icon is-small is-right"
                      @click="viewOldPassword()"
                    >
                      <i class="far fa-eye" v-if="!showOldPassword"></i>
                      <i class="far fa-eye-slash" v-else></i>
                    </a>
                  </div>
                </div>
                <p :class="{ passValid: isValid, passNotValid: !isValid }">
                  {{ validPassword }}
                </p>
                <p :class="{ passNotValid: isSamePass }">
                  {{ isPasswordCompared }}
                </p>
                <div class="field">
                  <div class="control has-icons-right">
                    <input
                      class="input"
                      :type="!showNewPassword ? 'password' : 'text'"
                      placeholder="Nueva contraseña"
                      name="password"
                      v-model="newPassword"
                    />
                    <a
                      class="icon is-small is-right"
                      @click="viewNewPassword()"
                    >
                      <i class="far fa-eye" v-if="!showNewPassword"></i>
                      <i class="far fa-eye-slash" v-else></i>
                    </a>
                  </div>
                </div>
                <p :class="{ passValid: isValid, passNotValid: !isValid }">
                  {{ validPassword }}
                </p>
                <div class="field">
                  <div class="control has-icons-right">
                    <input
                      class="input"
                      :type="!showRepeatedPassword ? 'password' : 'text'"
                      name="password2"
                      placeholder="Repetir contraseña"
                      autofocus=""
                      autocomplete="off"
                      v-model="repeatedPassword"
                    />
                    <a
                      class="icon is-small is-right"
                      @click="viewRepeatedPassword()"
                    >
                      <i class="far fa-eye" v-if="!showRepeatedPassword"></i>
                      <i class="far fa-eye-slash" v-else></i>
                    </a>
                  </div>
                </div>
                <p :class="{ passMatch: isMatch, passNotMatch: !isMatch }">
                  {{ isPasswordMatching }}
                </p>
                <button
                  :disabled="isButtonDisabled || isSamePass"
                  :class="{ 'is-loading': loading }"
                  class="button is-block is-info is-medium is-fullwidth"
                >
                <p>
                  Cambiar contraseña
                </p>
                </button>
              </form>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'

export default {
  name: 'ChangePassword',
  components: {
    TheHeader: () => import('@/components/TheHeader.vue'),
    TitleBar: () => import('@/components/ui/TitleBar.vue')
  },
  data () {
    return {
      oldPassword: '',
      newPassword: null,
      repeatedPassword: null,
      showNewPassword: false,
      showOldPassword: false,
      showRepeatedPassword: false,
      isMatch: false,
      isValid: false,
      isSamePass: false,
      regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,})/,
      loading: false
    }
  },
  computed: {
    ...mapState({ authentication: state => state.authentication }),
    isPasswordMatching () {
      if (this.newPassword && this.repeatedPassword) {
        if (this.newPassword !== this.repeatedPassword) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.isMatch = false
          return 'Las contraseñas no coinciden' && null
        } else {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.isMatch = true
          return 'Las contraseñas coinciden'
        }
      }
      return null
    },
    validPassword () {
      if (this.newPassword) {
        if (this.regex.test(this.newPassword)) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.isValid = true
          return 'La contraseña es válida'
        } else {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.isValid = false
          return 'La contraseña debe tener un número (0-9), al menos una letra minúscula (a-z) y mayúscula (A-Z), un carácter especial (@#$%&!) y 6 caracteres de largo.'
        }
      } else {
        return null
      }
    },
    validOldPassword () {
      if (this.oldPassword) {
        if (this.regex.test(this.oldPassword)) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.isValid = true
          return 'La contraseña es válida'
        } else {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.isValid = false
          return 'La contraseña debe tener un número (0-9), al menos una letra minúscula (a-z) y mayúscula (A-Z), un carácter especial (@#$%&!) y 6 caracteres de largo.'
        }
      } else {
        return null
      }
    },
    isPasswordCompared () {
      if (this.newPassword && this.oldPassword) {
        if (this.newPassword === this.oldPassword) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.isSamePass = true
          return 'La contraseña actual no puede coincidir con la nueva.'
        }
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.isSamePass = false
      return null
    },
    isButtonDisabled () {
      if (this.oldPassword) {
        if (this.validPassword && this.isPasswordMatching) {
          return false
        }
      } else if (!this.oldPassword) {
        return true
      } else if (this.loading) {
        return true
      }
      return true
    }
  },
  methods: {
    ...mapMutations(['setAuthenticationValue']),
    ...mapActions(['changePass']),
    changePassword () {
      this.loading = true
      const data = {
        oldPassword: this.oldPassword,
        newPassword: this.newPassword
      }
      this.changePass(data).then(resp => {
        this.loading = false
        if (resp) {
          this.$router.push('/')
        }
      })
    },
    viewOldPassword () {
      this.showOldPassword = !this.showOldPassword
    },
    viewRepeatedPassword () {
      this.showRepeatedPassword = !this.showRepeatedPassword
    },
    viewNewPassword () {
      this.showNewPassword = !this.showNewPassword
    }
  }
}
</script>
<style scoped>
.title-bar {
  margin-top: 63px;
}

.space {
  margin-top: 100px;
}
a.icon {
  cursor: pointer;
  pointer-events: auto !important;
}

#login {
  background-color: var(--main-background-color);
}
.avatar {
  margin-bottom: 50px;
}
.login {
  align-items: center;
  justify-content: center;
}
.box {
  background: transparent;
  box-shadow: none;
  padding: 40px;
}
.box .button {
  margin-top: 50px;
  background: #2c79b6;
}
.box .button p {
  font-size: 12.5px;
}
.hero-body {
  padding: 0rem 1.5rem !important;
}
.link,
.link:focus,
.link:active,
.link:visited {
  color: #ffffff;
}
p {
  color: #ffffff;
}
.passMatch {
  color: green;
  font-size: 13px;
}
.passNotMatch {
  color: red;
  font-size: 13px;
}
.passValid {
  color: green;
  font-size: 13px;
  margin-top: -8px;
  margin-bottom: 3px;
}
.passNotValid {
  color: red;
  font-size: 12px;
  margin-bottom: 3px;
}

</style>
